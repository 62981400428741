<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="7" lg="12" md="12" cols="12">
          <b-card
              no-body
              class="border-primary"
              v-if="aktarimInfo"
          >
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
              <h5 class="mb-0">
                Aktarım Bilgileri
              </h5>
              <b-badge variant="light-primary">
                #{{ aktarimInfo.aktarimID }}
              </b-badge>
              <small class="text-muted w-100">İşlem Tarihi : <strong>{{ aktarimInfo.kayitTarih.slice(0, 16) }}</strong></small>
            </b-card-header>
            <hr class="aktarim-detay-hr"/>
            <b-card-body class="p-2">
              <ul class="list-unstyled">
                <li>
                  <span class="align-middle">Aktarılan Ürün Sayısı: <strong>{{
                      aktarilanStoklar.length
                    }}</strong></span>
                </li>
                <li>
                  <span class="align-middle">İşlem Sahibi: <strong>{{ aktarimInfo.userName }}</strong></span>
                </li>
              </ul>
            </b-card-body>
          </b-card>
          <b-card
              v-if="fisDetay.siparisFisBilgileri"
              no-body
              class="border-primary"
          >
            <b-row class="m-50">
              <b-col cols="12" class="pr-0">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon float-right unPrint"
                    v-b-modal.fis-modal
                >
                  <feather-icon icon="ArchiveIcon" class="mr-50"/>
                  İndir Yada Yazdır
                </b-button>
              </b-col>
            </b-row>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                filename="Fatura ve Hizmet Karşılığı Yapılan Ödeme Tutanağı"
                :pdf-quality="pdfQuality"
                :manual-paginaton="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="odeme-tutanagi"
                id="html2Pdf"
            >
              <section slot="pdf-content" class=" pl-2 pr-2 font-size-12" ref="odeme-tutanagi">
                <b-row class="mb-3">
                  <b-col cols="12" class="text-center mt-5">
                    <h3 class="font-size-16">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</h3>
                    <h4 class="font-size-15">FATURA VE HİZMET KARŞILIĞI YAPILAN ÖDEME TUTANAĞI</h4>
                  </b-col>
                  <b-col cols="12" class="text-center mt-3"
                         style="display: flex;justify-content: space-between;font-weight: bold;"
                  >
                    <p>Fiş No : {{ fisNo }}</p>
                    <p>Fiş Tarihi : {{ fisTarihi.slice(0, 10) }}</p>
                  </b-col>
                  <b-col cols="12" class="mt-1" style="padding-left: 30px;padding-right: 30px;">
                    <b-row class="text-center">
                      <b-col xl="4" lg="4" md="4" xs="12" style="border: 1px solid black;">
                        Malın Cinsi
                      </b-col>
                      <b-col xl="1" lg="1" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                        Miktar
                      </b-col>
                      <b-col xl="2" lg="2" md="2" xs="12" style="border: 1px solid black;border-left: none;">
                        Birim
                      </b-col>
                      <b-col xl="2" lg="2" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                        Birim Fiyat
                      </b-col>
                      <b-col xl="1" lg="1" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                        Kdv
                      </b-col>
                      <b-col xl="2" lg="2" md="3" xs="12" style="border: 1px solid black;border-left: none;">
                        Tutar
                      </b-col>
                    </b-row>
                    <b-row class="text-center"
                           style="font-weight: bold;height: 450px;display: flow;border: 1px solid black;border-top: none;border-bottom: none"
                    >
                      <div v-for="item in fisDetay.siparisFisBilgileri.birimData" :key="item.stokID" class="d-flex">
                        <b-col xl="4" lg="4" md="4" xs="12"
                               style="border-right: 1px solid black;border-bottom: 1px solid black;"
                        >
                          {{ item.urunAdi }}
                        </b-col>
                        <b-col xl="1" lg="1" md="3" xs="12"
                               style="border-right: 1px solid black;border-bottom: 1px solid black;"
                        >
                          {{ item.adet }}
                        </b-col>
                        <b-col xl="2" lg="2" md="2" xs="12"
                               style="border-right: 1px solid black;border-bottom: 1px solid black;"
                        >
                          {{ item.birim }}
                        </b-col>
                        <b-col xl="2" lg="2" md="3" xs="12"
                               style="border-right: 1px solid black;border-bottom: 1px solid black;"
                        >
                          {{ item.tutar }}
                        </b-col>
                        <b-col xl="1" lg="1" md="3" xs="12"
                               style="border-right: 1px solid black;border-bottom: 1px solid black;"
                        >
                          {{ item.kdv }}
                        </b-col>
                        <b-col xl="2" lg="2" md="3" xs="12" style="border-bottom: 1px solid black;">
                          {{ item.toplamTutar }}
                        </b-col>
                      </div>
                    </b-row>
                    <b-row class="text-center"
                           style="font-weight: bold;border-top:1px solid black;"
                    >
                      <b-col xl="3" lg="3" md="3" xs="12"
                             style="border: 1px solid black;border-bottom: none;border-top: none;place-content: center;border-right: none;"
                      >
                        Yalnız, {{ tlCevir(parseFloat(finance().toplamTutar)) }}
                      </b-col>
                      <b-col xl="1" lg="1" md="3" xs="12"
                             style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none;"
                      >
                        &nbsp;
                      </b-col>
                      <b-col xl="1" lg="1" md="3" xs="12"
                             style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none;"
                      >
                        &nbsp;
                      </b-col>
                      <b-col xl="2" lg="2" md="3" xs="12"
                             style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;border-right: none"
                      >
                        &nbsp;
                      </b-col>
                      <b-col xl="1" lg="1" md="3" xs="12">
                        &nbsp;
                      </b-col>
                      <b-col xl="2" lg="2" md="3" xs="12" style="border-right: 1px solid black;">
                        <p class="m-0">Ara Toplam :</p>
                        <p class="m-0">Kdv Tutarı :</p>
                        <p class="m-0">Toplam Tutar :</p>
                      </b-col>
                      <b-col xl="2" lg="2" md="3" xs="12"
                             style="border: 1px solid black;border-left: none;border-bottom: none;border-top: none;"
                      >
                        <p class="m-0">{{ fisDetay.siparisFisOzeti.araToplam }}</p>
                        <p class="m-0">{{ fisDetay.siparisFisOzeti.toplamVergi }}</p>
                        <p class="m-0">{{ fisDetay.siparisFisOzeti.toplamTutar }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="text-center"
                           style="font-weight: bold;border-top:1px solid black;border-right: 1px solid black;border-left: 1px solid black;"
                    >
                      <b-col cols="3" v-for="item in semaListesi.komisyon" :key="item.uyeID"
                             style="padding:20px"
                      >
                        <p class="m-0" style="text-decoration: underline;"> {{ item.pozisyon }} </p>
                        <p class="m-0">{{ item.adSoyad }}</p>
                        <p class="m-0">{{ item.unvan }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="text-center"
                           style="font-weight: bold;border-bottom: 1px solid black;border-right: 1px solid black"
                    >
                      <b-col cols="9" style="border-left: 1px solid black;padding:20px;" class="text-left">
                        <p>Yukarıda belirtilen iş yada malzemelerin muayene ve kontrolleri yapılmış olup,
                          <br>
                          <strong>{{ fisDetay.siparisFisOzeti.firmaUnvan }}</strong>
                          <br>
                          adına teslim ve çıkışı yapılmıştır.</p>
                      </b-col>
                      <b-col cols="3"
                             style="padding:20px"
                      >
                        <p class="m-0" style="text-decoration: underline;"> Harcama Yetkilisi </p>
                        <p class="m-0">{{ semaListesi.yetkili.adSoyad }}</p>
                        <p class="m-0">{{ semaListesi.yetkili.unvan }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </section>
            </vue-html2pdf>
          </b-card>
          <b-card
              v-if="false && aktarimInfo"
              no-body
              class="border-primary"
          >
            <b-row class="m-50">
              <b-col cols="12" class="pr-0">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon float-right unPrint"
                    @click="cikartmaBelgesiPrint"
                >
                  <feather-icon icon="ArchiveIcon" class="mr-50"/>
                  Yazdır
                </b-button>
              </b-col>
            </b-row>
            <b-row class="px-2" id="cikartmaBelgesi" ref="cikartmaBelgesi">
              <b-col cols="12">
                <h6 class="text-left ml-1">
                  Aso {{ $store.getters.FIRMA_BILGILERI.unvan }}
                </h6>
                <h4 class="text-center">
                  İç Dağıtım - İsteme / Çıkartma Belgesi
                </h4>
              </b-col>
              <b-col cols="12">
                <h5 class="d-inline-block">İşletme : Depo Adı</h5>
                <h4 class="d-inline-block float-right text-danger font-family-math"> #{{ aktarimInfo.aktarimID }}</h4>
              </b-col>
              <b-col cols="12">
                <b-row class="mt-2">
                  <b-col cols="1" class="border">
                    <p class="mb-0 text-center font-weight-bolder">S.No:</p>
                  </b-col>
                  <b-col cols="4" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Cinsi</p>
                  </b-col>
                  <b-col cols="3" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Miktar</p>
                  </b-col>
                  <b-col cols="4" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Birim</p>
                  </b-col>
                  <div class="d-content" v-for="(item,i) in aktarilanStoklar" :key="i">
                    <b-col cols="1" class="border">
                      <p class="mb-0 text-center">{{ i + 1 }}</p>
                    </b-col>
                    <b-col cols="4" class="border">
                      <p class="mb-0 text-center">{{ item.urunAdi }}</p>
                    </b-col>
                    <b-col cols="3" class="border">
                      <p class="mb-0 text-center">{{ item.adet }}</p>
                    </b-col>
                    <b-col cols="4" class="border">
                      <p class="mb-0 text-center">{{ item.birim }}</p>
                    </b-col>
                  </div>
                  <b-col cols="5" class="border">
                    <p class="mb-0 text-center font-weight-bolder">Toplam</p>
                  </b-col>
                  <b-col cols="3" class="border"/>
                  <b-col cols="4" class="border"/>
                  <b-col cols="4" class="border border-right-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5 class="mb-0">Teslim Anında Teslim Alan</h5>
                    <small>(Kimlik ve İmza)</small>
                  </b-col>
                  <b-col cols="4" class="border border-left-0 border-right-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5>Tarih: &nbsp;&nbsp;{{ fisTarihi.slice(0, 10) }}</h5>
                    <b>Verilmesi Uygundur</b>
                    <p>Öğretmenevi Müdürü</p>
                  </b-col>
                  <b-col cols="4" class="border border-left-0 border-top-0 d-grid text-center p-2 pb-5">
                    <h5>Tarih: &nbsp;&nbsp;{{ fisTarihi.slice(0, 10) }}</h5>
                    <b>Verildi</b>
                    <p class="mb-0">Kiler, Depo Memuru</p>
                    <small>(Kimlik ve İmza)</small>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col xl="5" lg="12" md="12" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Aktarılan Ürünler</h4>
                </b-col>
              </div>
            </b-row>
            <b-card
                v-if="fisDetay.siparisFisBilgileri"
                no-body
                class="card-company-table mt-5"
            >
              <b-table
                  :items="fisDetay.siparisFisBilgileri.birimData"
                  responsive
                  :fields="fields"
                  class="mb-0 aktarim-detay"
                  striped
                  hover
              >
                <template #cell(urunAdi)="data">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="font-weight-bolder">
                        {{ data.item.urunAdi }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(adet)="data">
                  <div class="d-flex align-items-center">
                    <b-badge variant="primary">
                      <span>{{ data.item.adet }}</span>
                    </b-badge>
                  </div>
                </template>
                <template #cell(birim)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{ data.item.birim }}</span>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="fis-modal"
        centered
        title="Fiş No Giriniz"
        ok-only
        hide-footer
    >
      <b-row>
        <b-col :span="6">
          <b-form-group
              label="Fiş No"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                placeholder="Fiş No Giriniz"
                autocomplete="off"
                v-model="fisNo"
            />
          </b-form-group>
        </b-col>
        <b-col :span="6">
          <b-form-group
              label="Fiş Tarihi"
              label-for="basicInput"
          >
            <flat-pickr
                v-model="fisTarihi"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :span="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon float-right unPrint"
              @click="download('odeme-tutanagi')"
          >
            <feather-icon icon="ArchiveIcon" class="mr-50"/>
            İndir Yada Yazdır
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BContainer,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BButton,
  BCardHeader,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import VueHtml2pdf from 'vue-html2pdf'
import _ from 'lodash'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  name: 'FisDetay',
  components: {
    flatPickr,
    BFormGroup,
    BFormInput,
    VueHtml2pdf,
    BCardBody,
    BCard,
    BContainer,
    BBadge,
    BTable,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      customProduct: [
        {
          adi: 'Su Cam Bardak',
          miktar: '10,00',
          birim: 'Kg',
          birimFiyat: '68,500',
          kdv: '1',
          tutar: '687,90',
        },
        {
          adi: 'Masa',
          miktar: '20',
          birim: 'Düzine',
          birimFiyat: '100,50',
          kdv: '1',
          tutar: '100,000',
        },
      ],
      customKomisyon: [
        {
          pozisyon: 'Başkan',
          adSoyad: 'Gökhan Kuzucuoğlu',
          unvan: 'Müdür Yrd.',
        },
        {
          pozisyon: 'Yetkili',
          adSoyad: 'Turan Ali',
          unvan: 'Müdür',
        },
        {
          pozisyon: '3',
          adSoyad: '3',
          unvan: '3',
        },
        {
          pozisyon: '4',
          adSoyad: '4',
          unvan: '4',
        },
      ],
      fisNo: '',
      fisTarihi: '',
      detayID: '',
      aktarilanAdetMax: '',
      aktarilanAdet: '',
      geriAktarimID: '',
      aktarilanUrunAdi: '',
      id: this.$route.params.id,
      fields: [
        {
          key: 'urunAdi',
          label: 'Ürün Adı',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'adet',
          label: 'Adet',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'birim',
          label: 'Birim',
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
    }
  },
  computed: {
    semaListesi() {
      const harcamaYetkilisi = Object.values(store.getters.DEPO_KOMISYON_SEMA_GET)
          .filter(item => item.evrakTuru == 0 && item.pozisyon == 2)
      const komisyon = Object.values(store.getters.DEPO_KOMISYON_SEMA_GET)
          .filter(item => item.evrakTuru == 0 && item.pozisyon != 2)
      if (!komisyon.length) return []
      const yetkili = this.uyeler.find(item => {
        if (item.uyeID == harcamaYetkilisi[0].uyeID) {
          return {
            ...item,
            ...harcamaYetkilisi[0],
          }
        }
        return null
      })
      return {
        yetkili,
        komisyon: komisyon.map(item => {
          const uye = this.uyeler.find(uye => uye.uyeID == item.uyeID)
          return {
            ...uye,
            pozisyon: this.getPozisyonAdi(item.pozisyon),
          }
        })
      }
    },
    uyeler() {
      return Object.values(store.getters.DEPO_UYELER_GET)
    },
    currentDate() {
      return moment(this.fisDetay.siparisFisOzeti.kayitTarih)
          .format('DD[.]MM[.]YYYY')
    },
    aktarimInfo() {
      return store.getters.AKTARIM_DETAY_GET.bilgiler
    },
    aktarilanStoklar() {
      return store.getters.AKTARIM_DETAY_GET.aktarilanStoklar
    },
    fisDetay() {
      return {
        siparisFisOzeti: store.getters.FIS_DETAY_GET.siparisFisOzeti,
        siparisFisBilgileri: store.getters.FIS_DETAY_GET.siparisFisBilgileri,
      }
    },
  },
  methods: {
    getPozisyonAdi(a) {
      if (a == 0) return 'Üye'
      if (a == 1) return 'Başkan'
      if (a == 2) return 'Harcama Yetkilisi'
      return ''
    },
    getUyeler() {
      store.dispatch('depoUyeListesi')
    },
    getKomisyonSema() {
      store.dispatch('depoKomisyonSemaListesi')
    },
    tlCevir(sayi) {
      sayi = sayi.toString()
          .replace('.', ',')
      let rakam = sayi.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .split(',')
      let tamsayi = rakam[0]
      let ondalik = rakam[1] || ''

      var birler = ['', 'BİR', 'İKİ', 'ÜÇ', 'DÖRT', 'BEŞ', 'ALTI', 'YEDİ', 'SEKİZ', 'DOKUZ']
      var onlar = ['', 'ON', 'YİRMİ', 'OTUZ', 'KIRK', 'ELLİ', 'ALTMIŞ', 'YETMİŞ', 'SEKSEN', 'DOKSAN']
      var binler = ['', 'BİN', 'MİLYON', 'MİLYAR', 'TRİLYON', 'KATRİLYON', 'KENTİLYON']
      var sonuc = []

      var adim = 0
      for (let i = tamsayi.split('.').length; i > 0; i--) {
        sayi = tamsayi.split('.')[i - 1]
        if (sayi.length == 1) {
          sayi = '00' + sayi
        }
        if (sayi.length == 2) {
          sayi = '0' + sayi
        }
        let c = ''

        for (let j = 1; j < sayi.length + 1; j++) {
          if (j == 1 && sayi[j - 1] == 1) {
            c += ' YÜZ '
          } else if (j == 1 && birler[sayi[j - 1]] != '') {
            c += birler[sayi[j - 1]] + ' YÜZ '
          } else if (j == 2) {
            c += onlar[sayi[j - 1]] + ' '
          } else if (j == 3 && tamsayi.length == 5 && sayi[j - 1] == 1 && adim == 1) {
            c += ' '
          } else if (j == 3) {
            c += birler[sayi[j - 1]] + ' '
          }
        }

        if (c != '') {
          sonuc.push(c + binler[adim])
        }
        adim++
      }

      if (sonuc.length != 0) {
        sonuc = sonuc.reverse()
            .join(' ') + ' TL'
      } else {
        sonuc = ''
      }
      if (ondalik.length == 1) {
        ondalik = ondalik + '0'
      }
      if (ondalik != '') {
        sonuc += ' ' + onlar[ondalik[0]] + ' ' + birler[ondalik[1]] + ' KR'
      }

      sonuc = sonuc.replace(/ /g, ' ')
          .trim()
      return sonuc
    },
    finance() {
      return {
        toplamTutar: _.sumBy(this.fisDetay.siparisFisBilgileri.birimData, function (o) {
          return o.toplamTutar
        }),
      }
    },
    download(ref) {
      this.$bvModal.hide('fis-modal')
      this.$refs[ref].generatePdf()
    },
    cikartmaBelgesiPrint() {
      let printContents = document.getElementById('cikartmaBelgesi').innerHTML
      let originalContents = document.body.innerHTML
      let wrapperDiv = document.createElement('div')
      wrapperDiv.innerHTML = printContents
      wrapperDiv.style.padding = '50px'
      document.body.innerHTML = wrapperDiv.outerHTML
      window.print()
      document.body.innerHTML = originalContents
      location.reload()
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  created() {
    this.getUyeler()
    this.getKomisyonSema()
    store.dispatch('fisDetay', this.id)
    setTimeout(() => {
      this.fisTarihi = this.fisDetay.siparisFisOzeti.kayitTarih
    }, 1000)
  },
  beforeDestroy() {
    store.commit('FIS_DETAY_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.font-size-16 {
  font-size: 16px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

@import "@core/scss/vue/libs/vue-select.scss";

.layout-container .content-wrapper {
  display: contents !important;
}
</style>
<style>
@media print {
  #cikartmaBelgesi {
    @page {
      size: A5 !important;
    }
    width: 8cm;
    height: 5cm;
  }
}

.aktarim-detay .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.aktarim-detay .b-card-header {
  display: inline-block !important;
}
</style>
<style scoped>

.d-content {
  display: contents;
}

#sidebar-form {
  padding: 5%;
}

.search-input {
  border-color: #c3baba !important;
}

.search-bar {
  width: 20% !important;
}

.aktarim-detay-hr {
  margin: 0.6rem !important;
}

.font-family-math {
  font-family: math;
}

</style>
